import React, { FunctionComponent } from 'react';
import { Item } from '../Commander';
import { Command } from 'cmdk';

type Props = {
  item: Item;
  onSelect: (value: string) => void;
};

const PageCommanderItem: FunctionComponent<Props> = ({ item, onSelect }) => {
  return (
    <Command.Item onSelect={onSelect} value={item.value}>
      {item.icon && React.createElement(item.icon)} {item.value}
      {item.type && <span cmdk-raycast-meta="">{item.type}</span>}
    </Command.Item>
  );
};
export default PageCommanderItem;
