import { EntityType } from '../../Entity/EntityType';
import { FinancialCompanyType } from '../../FinancialCompany/FinancialCompanyType';
import { ProjectType } from '../../Project/Project';
import { ContractorType } from '../Contractor/ContractorType';
import { MoneyType } from '../../Money/MoneyType';
import { BranchType } from '../../Branch/BranchType';
import { BasicEmployeeType } from '../../Employee/EmployeeType';
import { CostTypeType } from '../Cost/CostTypeType';
import { SubcostType } from '../Cost/SubcostType';
import { Requestable, RequestType } from '../../Request/RequestType';

export type PurchaseStatus = 'accepted' | 'rejected' | 'pending' | 'closed';

export const PurchaseOrderSubject = 'Finances::PurchaseOrder';

export type PurchaseOrderType = EntityType &
  Requestable & {
    number?: number;
    project: ProjectType;
    billingType: 'contract' | 'invoice';
    costType: CostTypeType;
    tags: string[];
    contractor: ContractorType;
    currency: string;
    amountNet: MoneyType;
    originalAmountNet: MoneyType;
    deliveryDate: string;
    company: FinancialCompanyType;
    location: BranchType;
    title: string;
    description: string;
    acceptanceTerms: string;
    internalComment: string;
    status: PurchaseStatus;
    createdAt: string;
    createdBy: BasicEmployeeType;
    paymentTerms: '45_days' | '30_days' | '21_days' | 'special';
    specialPaymentTermsDetails?: string;
    specialPaymentTermsReason?: string;
    kind: SubcostType['kind'];
    remainNet: MoneyType;
    invoicedNet: MoneyType;
    originalRemainNet: MoneyType;
    originalInvoicedNet: MoneyType;
    uploadedResources?: (number | string)[];
    requests?: RequestType[];
    closed?: boolean;
    budgetSymbol?: string;
    name?: string;
    clientName?: string;
    isPrimary?: boolean;
    taxNumber?: string;
  };
