import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import Text from '../../../atoms/Text';
import api from '../../../../services/api';
import { ApiArrayResponseType } from '../../../../@types/Api/ApiResponseType';
import InventoryItemType from '../../../../@types/Inventory/InventoryItemType';
import Button from '../../../atoms/Button/Button';
import useModal from '../../../../hooks/useModal';
import AcceptInventoryModal from './AcceptInventoryModal';
import { InventoryAllocationRequestType } from '../../../../@types/Inventory/InventoryAllocationRequestType';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import Alert from '../../../molecules/Alert/Alert';
import WarningIcon from '../../../../Icons/warning.icon';
import TickIcon from '../../../../Icons/TickIcon';

type Props = {};

const MyInventoryProtocols: FunctionComponent<Props> = (props) => {
  const { showModal } = useModal();
  const {
    state: { user },
  } = useContext(AuthContext);
  const [requests, setRequests] = useState<InventoryAllocationRequestType[]>([]);
  const [items, setItems] = useState<InventoryItemType[]>([]);

  const loadProtocols = useCallback(() => {
    api
      .get<ApiArrayResponseType<InventoryAllocationRequestType>>('/inventory_allocation_requests', {
        params: {
          'allocation.person.id': user?.employeeId,
          status: 'pending',
        },
      })
      .then((response) => {
        setRequests(() => response.data['hydra:member']);
      });
  }, []);

  useEffect(() => {
    loadProtocols();
  }, []);

  useEffect(() => {
    if (!requests || requests.length === 0) {
      return;
    }
    const items: InventoryItemType[] = [];
    requests.forEach((requests) => {
      requests.allocation.items.forEach((item) => {
        items.push(item);
      });
    });
    setItems(items);
  }, [requests]);

  const confirmAcceptance = useCallback(() => {
    setItems([]);
    loadProtocols();
  }, [requests]);

  const showAcceptInventoryModal = useCallback(() => {
    showModal({
      title: 'Inventory acceptance',
      subtitle: `You have been assigned ${items.length} items. You will find list of items below.`,
      body: <AcceptInventoryModal items={items} requests={requests} onAccept={confirmAcceptance} />,
    });
  }, [requests, items]);

  return (
    <>
      {items.length > 0 && (
        <Alert
          kind={'warning'}
          text={
            <div style={{ display: 'flex' }}>
              <Text bold={true}>You have {items.length} items that requires acceptance.</Text>&nbsp;
              <Text>Please review them as soon as possible.</Text>
            </div>
          }
          button={<Button $text={'Accept inventory'} onClick={showAcceptInventoryModal} $icon={<TickIcon />} />}
          icon={<WarningIcon />}
        />
      )}
    </>
  );
};

export default MyInventoryProtocols;
