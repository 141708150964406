import React, { FunctionComponent } from 'react';
import * as Styled from './ProjectTime.styled';
import { ProjectTimeGrid } from './ProjectTime.styled';
import { ProjectType } from '../../../../../../@types/Project/Project';
import TimeProgress from '../../../../../molecules/TimeProgress/TimeProgress';

type Props = {
  project: ProjectType;
  componentGrid?: ProjectTimeGrid;
};

const ProjectTime: FunctionComponent<Props> = ({ project, componentGrid }) => {
  return (
    <Styled.ProjectTimeStyled
      $columnGap={componentGrid?.$columnGap}
      $rowGap={componentGrid?.$rowGap}
      $gridTemplateColumns={componentGrid?.$gridTemplateColumns}
    >
      {project.clientServiceWork && (
        <TimeProgress current={project.clientServiceWork.total} estimated={project.clientServiceWork.estimated * 60} label={'Partners Care'} />
      )}
      {project.developmentWork && (
        <TimeProgress current={project.developmentWork.total} estimated={project.developmentWork.estimated * 60} label={'Technology'} />
      )}
      {project.creationWork && <TimeProgress current={project.creationWork.total} estimated={project.creationWork.estimated * 60} label={'Creation'} />}
      {project.mediaWork && <TimeProgress current={project.mediaWork.total} estimated={project.mediaWork.estimated * 60} label={'Media'} />}
      {project.strategyWork && <TimeProgress current={project.strategyWork.total} estimated={project.strategyWork.estimated * 60} label={'Strategy'} />}
      {project.socialMediaWork && (
        <TimeProgress current={project.socialMediaWork.total} estimated={project.socialMediaWork.estimated * 60} label={'Social media'} />
      )}
      {project.administrationWork && (project.administrationWork.total > 0 || project.administrationWork.estimated > 0) && (
        <TimeProgress current={project.administrationWork.total} estimated={project.administrationWork.estimated * 60} label={'Administration'} />
      )}
      {project.marketingWork && (project.marketingWork.total > 0 || project.marketingWork.estimated > 0) && (
        <TimeProgress current={project.marketingWork.total} estimated={project.marketingWork.estimated * 60} label={'Marketing'} />
      )}
      {project.peopleAndCultureWork && (project.peopleAndCultureWork.total > 0 || project.peopleAndCultureWork.estimated > 0) && (
        <TimeProgress current={project.peopleAndCultureWork.total} estimated={project.peopleAndCultureWork.estimated * 60} label={'P&C'} />
      )}
      {project.designStudioWork && (project.designStudioWork.total > 0 || project.designStudioWork.estimated > 0) && (
        <TimeProgress current={project.designStudioWork.total} estimated={project.designStudioWork.estimated * 60} label={'Design Studio'} />
      )}
    </Styled.ProjectTimeStyled>
  );
};

export default ProjectTime;
