import React, { FunctionComponent } from 'react';
import * as Styled from './History.styled';
import Heading from '../../../../../atoms/Heading';
import { InventoryAllocationType } from '../../../../../../@types/Inventory/InventoryAllocationType';
import HistoryItem from '../HistoryItem/HistoryItem';
import Text from '../../../../../atoms/Text';
import { AuthContext } from '../../../../../../Context/auth-context/auth-context';
import InventoryItemType from '../../../../../../@types/Inventory/InventoryItemType';
import DeleteButton from '../../Buttons/DeleteButton';
import EditButton from '../../Buttons/EditButton';
import AssignButton from '../../Buttons/AssignButton';
import OfficeUseButton from '../../Buttons/OfficeUseButton';
import DamagedButton from '../../Buttons/DamagedButton';
import RestoreButton from '../../Buttons/RestoreButton';
import ReturnButton from '../../Buttons/ReturnButton';

type Props = {
  entity: InventoryItemType;
  allocations?: InventoryAllocationType[];
};

const History: FunctionComponent<Props> = ({ allocations, entity }) => {
  const { can } = React.useContext(AuthContext);
  const isAdmin = React.useMemo(() => {
    return can('INVENTORY.CAN_MANAGE');
  }, [can]);

  const itemStatus = entity.status;

  return (
    <Styled.History colSpan={5}>
      <Heading size={'s'}>History</Heading>
      <Styled.HistoryItemsWrapper>
        {!allocations ||
          (allocations && allocations.length === 0 && (
            <Text color={'grey'} size={'s'}>
              No history yet
            </Text>
          ))}
        {allocations && allocations.map((allocation) => <HistoryItem key={allocation['@id']} allocation={allocation} />)}
      </Styled.HistoryItemsWrapper>

      {isAdmin && (
        <Styled.ButtonsWrapper>
          <DeleteButton entity={entity} />
          <EditButton entity={entity} />
          {itemStatus === 'stock' && <AssignButton entity={entity} />}
          <OfficeUseButton entity={entity} />
          <DamagedButton entity={entity} />
          <RestoreButton entity={entity} />
          <ReturnButton entity={entity} />
        </Styled.ButtonsWrapper>
      )}
    </Styled.History>
  );
};

export default History;
