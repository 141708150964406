import styled, { css } from 'styled-components';
import { color } from 'styles/Variables';

type PropType = {
  $active: boolean;
  $position?: 'right' | 'top' | 'top-right' | 'center';
  $width?: number;
  style?: any;
};

export const Popover = styled.div<PropType>`
  $position: relative;
`;

export const Body = styled.div<PropType>`
  position: absolute;
  z-index: 9;
  display: none;
  width: 100%;
  min-width: ${(props) => (props.$width ? `${props.$width}rem` : 'min-content')};
  background-color: ${color.neutral[10]};
  padding: 0.5rem 1rem;
  border: 1px solid ${color.neutral[20]};
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem ${color.neutral[40]};

  ${(props) =>
    props.$active &&
    css`
      display: block;
    `}
  ${(props) => [
    props.$position === 'right' &&
      css`
        right: 0;
      `,
    props.$position === 'top' &&
      css`
        top: calc(-100% - 0.5rem);
      `,
    props.$position === 'top-right' &&
      css`
        top: calc(-100% - 0.5rem);
        right: 0;
      `,
    props.$position === 'center' &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `,
  ]};
`;
