import React, { FunctionComponent } from 'react';
import Text from '../../../atoms/Text/Text';
import { GridItem, GridItemProps } from '../../../atoms/Grid/Grid';
import IdText from '../../IdText/IdText';
import * as Styled from '../Common.styled';
import { Link } from 'react-router-dom';
import { shorten } from '../../../../helpers/shorten';
import Chip from '../../../atoms/Chips/Chip';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';

type Props = GridItemProps & {
  contractor?: ContractorType;
};

const ContractorSingleDetail: FunctionComponent<Props> = ({ contractor, $desktop = 'auto', ...props }) => {
  return (
    <GridItem style={{ display: 'flex', flexDirection: 'column' }} {...props} $desktop={$desktop}>
      <Text color={'grey'} size={'s'} bold>
        Vendor
      </Text>
      {contractor && (
        <Styled.ClickableSingleDetail>
          <Link to={`/finances/vendors/vendors/${contractor?.id}/show`} target={'_blank'}>
            <GridItem style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
              <IdText withHash={true} value={contractor?.id} size={'l'} />
              <Text size={'l'}>{shorten(contractor?.name as string, 30, 30)}</Text>
            </GridItem>
            <GridItem>
              <Chip>{contractor?.taxNumber}</Chip>
            </GridItem>
          </Link>
        </Styled.ClickableSingleDetail>
      )}
      {!contractor && <Text size={'l'}>No vendor</Text>}
    </GridItem>
  );
};

export default ContractorSingleDetail;
