import { TableFiltersType } from '../../../../../../legacy/CapeMorris/@types';

const inventoryItemTableFilters: TableFiltersType = [
  {
    type: 'text',
    name: 'id',
    label: 'ID',
    gridProps: {
      $desktop: 1,
    },
    placeholder: 'Item ID',
  },
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    gridProps: {
      $desktop: 2,
    },
    placeholder: 'Type item name',
  },
  {
    type: 'text',
    name: 'manufacturer',
    label: 'Manufacturer',
    gridProps: {
      $desktop: 2,
    },
    placeholder: 'eg. Apple',
  },

  {
    type: 'select',
    name: 'type.id',
    options: {
      baseUrl: '/api/inventory_types',
    },
    label: 'Type',
    gridProps: {
      $desktop: 2,
    },
    placeholder: 'Select type',
  },
  {
    type: 'text',
    name: 'serialNumber',
    label: 'Serial number',
    gridProps: {
      $desktop: 1,
    },
    placeholder: 'Serial number',
  },

  {
    type: 'employee',
    name: 'assignedTo.id',
    label: 'Assignee',
    options: {
      group: 'organisation',
      all: true,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'status',
    options: {
      options: [
        { value: 'released', label: 'Released' },
        { value: 'stock', label: 'In Stock' },
        { value: 'damaged', label: 'Damaged' },
        { value: 'pending', label: 'Pending' },
        { value: 'common_use', label: 'Office use' },
      ],
    },
    label: 'Status',
    gridProps: {
      $desktop: 2,
    },
    placeholder: 'Select item status',
  },
];

export default inventoryItemTableFilters;
