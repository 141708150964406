import axios from 'axios';
import serverEvents from './serverEvents';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENTRY_POINT ?? '',
  withCredentials: true,
});
api.interceptors.response.use((response) => {
  if (serverEvents.state === 'closed') {
    serverEvents.init();
  }

  return response;
});
api.interceptors.request.use((config) => {
  console.log('morwa', config.url);
  if (config.url?.startsWith('/api')) {
    config.url = config.url?.substring(4);
  }
  const impersonate = sessionStorage.getItem('impersonate');
  if (impersonate) {
    config.headers['X-Switch-User'] = impersonate;
  }
  return config;
});

export const createFullApiUrl = (path: string) => {
  if (path[0] !== '/') {
    path = '/' + path;
  }
  if (path.startsWith('/api')) {
    path = path.substr(4);
  }
  return (process.env.REACT_APP_API_ENTRY_POINT ?? '') + path;
};

export default api;
