import React, { FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react';
import * as Styled from './Popover.styled';
import useOutsideClick from 'hooks/useOutsideClick';
import { CSSProperties } from 'styled-components';

type Props = {
  children: ReactNode;
  popover: ReactNode | string;
  className?: string;
  position?: 'right' | 'top' | 'top-right' | 'center';
  duration?: number;
  onHover?: boolean;
  width?: number;
  style?: CSSProperties;
};

const Popover: FunctionComponent<Props> = ({ children, popover, className, position, duration, onHover, width, style }) => {
  const [active, setActive] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const toggle = useCallback(() => {
    if (!active) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [active]);

  const deactivate = useCallback(() => {
    setActive(false);
  }, []);

  const handleDuration = useCallback(() => {
    setTimeout(() => {
      setActive(false);
    }, duration);
  }, []);

  useOutsideClick(popoverRef, deactivate);

  return (
    <Styled.Popover ref={popoverRef} className={className} $active={active} onClick={duration ? handleDuration : () => {}}>
      <div onClick={onHover ? undefined : toggle} onMouseEnter={onHover ? toggle : undefined} onMouseLeave={onHover ? toggle : undefined}>
        {children}
      </div>
      <Styled.Body $position={position} $active={active} $width={width} style={style}>
        {popover}
      </Styled.Body>
    </Styled.Popover>
  );
};

export default Popover;
