import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArticleType } from '../../../../@types/Article/ArticleType';
import { CategoryType } from '../../../../@types/Category/CategoryType';
import api from '../../../../services/api';

const WikiRedirect: FunctionComponent = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl = `/wiki/${type === 'category' ? 'wiki_categories' : 'articles'}/${id}`;
    api
      .get<ArticleType | CategoryType>(apiUrl)
      .then((response) => {
        console.log('morwa response', response);
        navigate(response.data.fullPath, { replace: true });
      })
      .catch((error) => {
        console.log('morwa error', error);
      });
  }, []);
  return <div></div>;
};

export default WikiRedirect;
