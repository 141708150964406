import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import * as Styled from './TableFilters.styled';
import TableFiltersForm from './TableFiltersForm';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../../../services/api';

type Props = {
  filters: TableFiltersType;
  defaultFilters?: any;
  onUpdate: (filters: any) => void;
  isFetching: boolean;
  context: string;
};

const handleFilter = async (filterName: string, value: any, filters: TableFiltersType) => {
  if (filterName.endsWith('.id')) {
    const filter = filters.find((f) => f.name === filterName);
    if (!filter) {
      return Promise.resolve(undefined);
    }
    if (filter.type === 'employee') {
      return api.get(`/employees/${value}`).then((response) => ({
        key: filterName,
        value: response.data,
      }));
    } else if (filter.type === 'department') {
      return api.get(`/departments/${value}`).then((response) => ({
        key: filterName,
        value: response.data,
      }));
    } else if (filter.type === 'select' && filter.options.baseUrl) {
      return api.get(filter.options.baseUrl + '/' + value).then((response) => ({
        key: filterName,
        value: response.data,
      }));
    }
    return Promise.resolve({
      key: filterName,
      value,
    });
  }
  return Promise.resolve({
    key: filterName,
    value,
  });
};

const handleFilters = async (values: any, filters: TableFiltersType) => {
  const promises = [];
  for (const key in values) {
    promises.push(handleFilter(key, values[key], filters));
  }
  return Promise.all(promises).then((values) => {
    const result: any = {};
    for (const value of values) {
      if (value === undefined) {
        continue;
      }
      result[`${value.key.split('.').join('__').split('[').join('<').split(']').join('>')}`] = value.value;
    }
    return result;
  });
};

const TableFilters: FunctionComponent<Props> = ({ filters, onUpdate, defaultFilters = {}, isFetching, context }) => {
  const [combinedFilters, setCombinedFilters] = useState<any | undefined>();
  const [resetKey, setResetKey] = useState(0);
  const [init, setInit] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const handleUpdate = useCallback(
    (filters: any) => {
      const newSearch = new URLSearchParams(search);
      for (const key in filters) {
        newSearch.set(key, filters[key]);
      }
      for (const key in filters) {
        if (!filters[key]) {
          newSearch.delete(key);
        }
      }
      for (const key in newSearch.keys()) {
        if (!filters[key]) {
          newSearch.delete(key);
        }
      }
      if (init) {
        newSearch.set('page', '1');
      }
      sessionStorage.setItem(`TABLE::${context}`, JSON.stringify(Object.fromEntries(newSearch.entries())));
      navigate(`${pathname}?${newSearch.toString()}`, { replace: true });

      onUpdate(filters);
    },
    [search, init],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const localStorageValue = sessionStorage.getItem(`TABLE::${context}`);
    const filtersFromStorage = localStorageValue ? JSON.parse(localStorageValue) : {};
    const filtersFromUrl: any = {};
    for (const key of searchParams.keys()) {
      filtersFromUrl[key] = searchParams.get(key);
    }

    setTimeout(() => {
      setInit(true);
    }, 500);

    handleFilters({ ...filtersFromStorage, ...filtersFromUrl }, filters).then((values) => {
      setCombinedFilters({ ...defaultFilters, ...values });
    });
  }, []);

  const doReset = useCallback(() => {
    setCombinedFilters(defaultFilters);
    setTimeout(() => {
      setResetKey(Math.random());
    }, 50);
  }, [defaultFilters]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Styled.TableFiltersContainer>
        {combinedFilters && <TableFiltersForm filters={filters} onUpdate={handleUpdate} defaultFilters={combinedFilters} key={resetKey} />}
      </Styled.TableFiltersContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Styled.TableStatus $visible={isFetching}>Update in progress</Styled.TableStatus>
        <Styled.TableFiltersResetButton onClick={doReset}>Reset</Styled.TableFiltersResetButton>
      </div>
    </div>
  );
};

export default TableFilters;
