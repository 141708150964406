import styled, { css } from 'styled-components';
import { color, fontSize } from '../../../styles/Variables';
import { RichTextStyles } from '../RichText/RichText.styled';

type PropType = {
  $active?: boolean;
  $hideToolbar?: boolean;
};

export const CKEditorWrapper = styled.div<PropType>`
  height: 100%;

  .ck-editor {
    height: 100%;
  }

  .ck-editor__main {
    overflow-y: auto;
    height: calc(100% - 4rem);
    background-color: ${color.neutral[10]} !important;
  }

  .ck-sticky-panel__content_sticky {
    background-color: ${color.light};
    transform: translateY(1rem);
  }

  .ck-editor__top {
    ${($hideToolbar) =>
      $hideToolbar &&
      css`
        display: none;
      `}
  }

  .ck.ck-toolbar {
    background: transparent !important;
    border: 0 !important;
    border-bottom: 1px #c3c3c3 solid !important;
    padding: 0 0.5rem !important;
    height: 4rem;
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: 0 !important;
  }

  .ck-editor__editable {
    border: 0 !important;
    min-height: 4rem;
    background: transparent !important;
    box-shadow: none !important;
    padding: 0 1rem !important;
    font-size: ${fontSize.body.m} !important;
    transition:
      min-height 0.4s,
      padding 0.4s !important;
  }

  .ck.ck-placeholder:before,
  .ck .ck-placeholder:before {
    color: ${color.neutral['60']} !important;
    font-size: ${fontSize.body.s} !important;
  }

  ${(props) =>
    props.$active &&
    css`
      .ck-editor__editable {
        min-height: 8rem;
      }

      .ck-editor__top {
        display: block;
      }

      .attachments-dropzone {
        opacity: 1;
        visibility: visible;
        height: 6rem;
      }

      .attachment-btn {
        display: flex;
      }

      .comments-form__footer {
        opacity: 1;
        visibility: visible;
        height: auto;
      }
    `}
  .mention {
    color: ${color.primary[80]};
    background-color: ${color.primary[10]};
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    background-color: ${color.neutral[10]} !important;
    min-height: 60px;
    padding-bottom: 0;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  .ck-balloon-panel {
    display: none !important;
  }

  ${RichTextStyles};
`;
