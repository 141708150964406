import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { ProjectListItemType } from '../../../../../@types/Project/Project';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import monthlyDate from '../../../../../helpers/monthlyDate';
import Text from '../../../../atoms/Text';

const ProjectDatesTemplate: CellTemplate<string | Date, {}, ProjectListItemType> = ({ value, item }) => {
  return (
    <Grid>
      <GridItem>
        <Grid style={{ marginBottom: '0.6rem' }}>
          <GridItem $desktop={'max'}>
            {item.settlementDate && (
              <Text color={'grey'} size={'s'} bold>
                {monthlyDate(item.settlementDate)}
              </Text>
            )}
            {!item.settlementDate && (
              <Text color={'lightGrey'} size={'s'} bold>
                N/A
              </Text>
            )}
          </GridItem>
        </Grid>
        <Grid style={{ marginBottom: '0.6rem' }}>
          <GridItem $desktop={'max'}>
            {item.plannedCompletionDate && (
              <Text color={'darkGrey'} size={'s'} bold>
                {monthlyDate(item.plannedCompletionDate)}
              </Text>
            )}
            {!item.plannedCompletionDate && (
              <Text color={'lightGrey'} size={'s'} bold>
                N/A
              </Text>
            )}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};
export default ProjectDatesTemplate;
