import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Grid } from '../../../atoms/Grid/Grid';
import TableFilter from './TableFilter';
import { FormProvider, useForm } from 'react-hook-form';
import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import IriHelper from '../../../../helpers/iri-helper';
import createUuid from '../../../../helpers/createUuid';
import Button from '../../../atoms/Button/Button';

type Props = {
  filters: TableFiltersType;
  defaultFilters?: any;
  onUpdate: (filters: any) => void;
};

const TableFiltersForm: FunctionComponent<Props> = ({ filters, defaultFilters, onUpdate }) => {
  const [state, setState] = React.useState<string>('');
  const handleChange = useCallback(() => {
    setState(createUuid());
  }, []);
  const form = useForm({
    defaultValues: {
      filters: defaultFilters,
    },
  });

  useEffect(() => {
    const t = setTimeout(() => {
      const values: any = { ...form.getValues()['filters'] };
      const result: any = {};
      for (let key in values) {
        let value = values[key];
        if (typeof values[key] === 'string') {
          value = values[key] && values[key].startsWith('/api') ? IriHelper.iriToId(values[key]) : values[key];
        } else if (typeof values[key] === 'boolean') {
          value = values[key] ? 1 : 0;
        } else if (Array.isArray(values[key])) {
          value = values[key].filter((v: string) => v !== '').map((v: string) => (v && v.startsWith('/api') ? IriHelper.iriToId(v) : v));
        } else if (typeof values[key] === 'object') {
          for (let objKey in values[key]) {
            if (values[key][objKey] === undefined || values[key][objKey] === null) {
              delete values[key][objKey];
            }
          }
        }
        result[key.split('__').join('.').split('<').join('[').split('>').join(']')] = value;
      }
      onUpdate(result);
    }, 500);
    return () => clearTimeout(t);
  }, [state]);

  return (
    <FormProvider {...form}>
      <Grid spacing={4} $align={'center'}>
        {filters.map((filter) => (
          <TableFilter filter={filter} key={`filter-${filter.name}`} onChange={handleChange} />
        ))}
      </Grid>
    </FormProvider>
  );
};

export default TableFiltersForm;
