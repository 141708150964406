import { UserType } from '../../@types/User/user';
import React from 'react';
export type AuthStateType = {
  user: UserType | null;
  state: 'LOADING' | 'REFRESH' | 'LOGGED_OUT' | 'LOGGED_IN' | 'INVALID_CREDENTIALS';
  context: string | null;
};

export type AuthContextType = {
  state: AuthStateType;
  dispatch: React.Dispatch<any>;
  authenticate: (username: string, password: string, from?: string) => any;
  logout: () => void;
  can: (role: string) => boolean;
  isImpersonating: boolean;
  loadUser: (from?: string, overrideCheck?: boolean) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  state: { user: null, state: 'LOADING', context: null },
  dispatch: () => {},
  authenticate: () => {},
  logout: () => {},
  isImpersonating: false,
  can: () => false,
  loadUser: () => {},
});
