import React, { FunctionComponent, useMemo } from 'react';
import Select, { SelectProps } from '../SelectInput/SelectInput';
import qs from 'qs';

type Props = SelectProps & {
  group: 'team' | 'organisation';
  all?: boolean;
  context?: string;
};

const EmployeeInput: FunctionComponent<Props> = ({ group = 'team', context, all = false, ...props }) => {
  const baseUrl = useMemo(() => {
    let url = group === 'organisation' ? '/api/my-company-team/' : '/api/my-team/';
    let params: any = {};

    if (!all) {
      params['status'] = 'active';
    }
    if (context) {
      params['context'] = context;
    }
    return `${url}?${qs.stringify(params)}`;
  }, [group, context]);
  return <Select {...props} baseUrl={baseUrl} />;
};

export default EmployeeInput;
