export type WeekDaysType = Date[];

const daysMilliseconds = 1000 * 60 * 60 * 24;

export const dayNamesArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const getDayName = (day?: Date) => {
  if (!day) {
    return '';
  }
  return dayNamesArray[day?.getDay() - 1];
};
//todo: cleanup
export const findMonday = (): Date => {
  // get current day and return monday from the same week.
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  const monday = new Date(today.setDate(diff));
  monday.setHours(5, 0, 0);
  return monday;
};

export const findFriday = () => {
  const monday = findMonday();
  const friday = new Date(monday.getTime() + 4 * daysMilliseconds);
  friday.setHours(23, 0, 0);
  return friday;
};

export const getPreviousWeek = (day: Date): Date => {
  return new Date(day.getTime() - 7 * daysMilliseconds);
};

export const getNextWeek = (day: Date): Date => {
  return new Date(day.getTime() + 7 * daysMilliseconds);
};

export const getCurrentWeekDays = () => {
  const currentDay = new Date();
  const day = currentDay.getDay();
  const dayTime = currentDay.getTime();
  const mondayTime = dayTime - (day - 1) * daysMilliseconds;
  const fridayTime = mondayTime + 4 * daysMilliseconds;
  let monday = new Date(mondayTime);
  let friday = new Date(fridayTime);
  monday.setHours(5, 0, 0);
  friday.setHours(17, 0, 0);
  return [monday, friday];
};

export const getPrevOrNextWeekDays = (selectedWeekDays: WeekDaysType, directionNumber: number) => {
  const mondayTime = selectedWeekDays[0].getTime();
  const fridayTime = selectedWeekDays[1].getTime();
  const prevOrNextMondayTime = mondayTime + directionNumber * 7 * daysMilliseconds;
  const prevOrNextFridayTime = fridayTime + directionNumber * 7 * daysMilliseconds;
  const monday = new Date(prevOrNextMondayTime);
  const friday = new Date(prevOrNextFridayTime);
  return [monday, friday];
};

export const getSelectedWeekDaysArray = (selectedWeekDays: WeekDaysType) => {
  let daysArray = [selectedWeekDays[0]];
  const mondayTime = selectedWeekDays[0].getTime();
  for (let i = 1; i <= 4; i++) {
    const dayTime = mondayTime + i * daysMilliseconds;
    const dayData = new Date(dayTime);
    daysArray.push(dayData);
  }
  return daysArray;
};
