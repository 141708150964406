import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import TaskAltIcon from '../../../../../../Icons/TaskAlt.icon';
import { color } from '../../../../../../styles/Variables';
import Button from '../../../../../atoms/Button/Button';
import DashboardCard from '../DashboardCard';
import Tabs, { TabObject } from '../../../../../molecules/Tabs/Tabs';
import TaskCardsContentCard from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/TaskCardsContentCard';
import useUser from '../../../../../../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import useTasksProvider from '../../../../../../hooks/useTasksProvider';
import { TaskStatusType } from '../../../../../../@types/Task/TaskType';
import { Icon } from '../../../../../atoms/Icon/Icon';
import { FilterIcon } from '../../../../../../Icons/Filter.icon';
import useModal from '../../../../../../hooks/useModal';
import DashboardConfigurationModal from '../DashboardConfigurationModal';
import { MeetingType } from '../../../../../../@types/Meeting/MeetingType';
import api from '../../../../../../services/api';

type Props = {};

export type UpcomingMyTasksFiltersType = {
  'assignee.id': number;
  status: TaskStatusType[];
  'deadline[before]'?: string;
  'order[deadline]'?: 'ASC' | 'DESC';
  showAlsoTaskWithNoDeadline?: boolean;
};

const MyTasks: FunctionComponent<Props> = () => {
  const user = useUser();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const today = new Date();
  const twoWeeksAgo = new Date().getTime() - 14 * 24 * 60 * 60 * 1000;
  const [meetings, setMeetings] = useState<undefined | MeetingType[]>(undefined);
  const [key, setKey] = useState(100);

  const { data: overdueTasks } = useTasksProvider('MyTasks::Overdue', {
    'assignee.id': user.employeeId,
    'deadline[strictly_before]': new Date(today).toISOString().slice(0, 10),
    status: ['todo', 'in_progress'],
  });
  const { data: tasksToAccept } = useTasksProvider('MyTasks::ToAccept', {
    'createdBy.id': user.employeeId,
    status: ['acceptance'],
  });

  const { data: tasksToReview } = useTasksProvider('MyTasks::ToReview', {
    'reviewer.id': user.employeeId,
    status: ['in_review'],
  });

  const handleConfigurationChange = useCallback(() => {
    setKey((p) => p + 1);
  }, [user]);

  useEffect(() => {
    if (!user.dashboardConfiguration.showMeetings) {
      setMeetings([]);
      return;
    }
    api
      .get('/api/outlook_meetings/my', {
        params: {
          'start[from]': today.toISOString().substring(0, 10),
          'start[to]': today.toISOString().substring(0, 10),
        },
      })
      .then((response) => {
        setMeetings(response.data['hydra:member'].filter((m: MeetingType) => new Date(m.end).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)));
      });
  }, [user]);

  const myTasksTabs: TabObject[] = [
    {
      tabName: 'Upcoming',
      component: <TaskCardsContentCard meetings={meetings} ctx={'MyTasks::Upcoming'} key={1} taskFormType={'inline'} customUrl={'/upcoming-tasks'} />,
      action: (
        <Icon
          onClick={() =>
            showModal({
              title: 'Filters',
              body: <DashboardConfigurationModal onSubmit={handleConfigurationChange} />,
            })
          }
          color={color.neutral['60']}
        >
          <FilterIcon />
        </Icon>
      ),
      tabIdName: 'tab-my-tasks-upcoming',
    },
    {
      tabName: 'Overdue',
      tabIdName: 'tab-my-tasks-overdue',
      component: (
        <TaskCardsContentCard
          ctx={'MyTasks::Overdue'}
          filters={{
            'assignee.id': user.employeeId,
            'deadline[strictly_before]': new Date(today).toISOString().slice(0, 10),
            status: ['todo', 'in_progress'],
            'order[deadline]': 'ASC',
          }}
          key={2}
          taskFormType={'none'}
        />
      ),
      count: overdueTasks?.length || 0,
    },
    {
      tabName: 'To review',
      tabIdName: 'tab-my-tasks-to-review',
      component: (
        <TaskCardsContentCard
          ctx={'MyTasks::ToReview'}
          filters={{
            'reviewer.id': user.employeeId,
            status: ['in_review'],
          }}
          key={3}
          taskFormType={'short'}
        />
      ),
      count: tasksToReview?.length || 0,
    },
    {
      tabName: 'To accept',
      tabIdName: 'tab-my-tasks-to-accept',
      component: (
        <TaskCardsContentCard
          ctx={'MyTasks::ToAccept'}
          filters={{
            'createdBy.id': user.employeeId,
            status: ['acceptance'],
          }}
          key={4}
          taskFormType={'short'}
        />
      ),
      count: tasksToAccept?.length || 0,
    },
    {
      tabName: 'Done',
      tabIdName: 'tab-my-tasks-done',
      component: (
        <TaskCardsContentCard
          ctx={'MyTasks::Completed'}
          filters={{
            'assignee.id': user.employeeId,
            'completedAt[after]': new Date(twoWeeksAgo).toISOString().slice(0, 10),
            'order[completedAt]': 'DESC',
            completed: 1,
          }}
          key={5}
          taskFormType={'none'}
        />
      ),
    },
  ];

  return (
    <DashboardCard
      title={'My tasks'}
      icon={<TaskAltIcon size={2} color={color.primary['60']} />}
      action={<Button $text={'Show all my tasks'} kind={'link'} onClick={() => navigate('/projects/my-work/my-tasks')} />}
      key={key}
    >
      <Tabs tabs={myTasksTabs} />
    </DashboardCard>
  );
};

export default MyTasks;
