import { TaskListType } from '../@types/Task/TaskType';
import api from '../services/api';
import { ArrayResponseType } from '../@types/hydra/hydra';
import qs from 'qs';
import { useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect } from 'react';
import serverEvents from '../services/serverEvents';
import axios from 'axios';

type TasksProviderReturnType = {
  data: TaskListType[] | undefined;
  count: number | undefined;
  isLoading: boolean;
};

const createUrl = (url: string, filters: any) => {
  if (!filters) {
    return url;
  }
  return `${url}?${qs.stringify(filters)}`;
};

const fetchData = async (filters: any = null, signal: any, customUrl?: string) => {
  const _url = createUrl(customUrl ?? '/api/tasks', {
    ...filters,
    _archived: 0,
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const promise = api.get<ArrayResponseType>(_url, { cancelToken: source.token }).then((response) => {
    return response.data;
  });

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query');
  });

  return promise;
};

function useTasksProvider(ctx: string, filters: any = null, customUrl?: string): TasksProviderReturnType {
  const { data, isLoading } = useQuery<ArrayResponseType<TaskListType>>([ctx, filters], ({ signal }) => fetchData(filters, signal, customUrl), {
    keepPreviousData: true,
  });
  const client = useQueryClient();

  const invalidate = useCallback(() => {
    client.invalidateQueries({
      queryKey: [ctx],
    });
  }, [client, ctx]);

  useEffect(() => {
    const listener = serverEvents.listen('/api/tasks').subscribe((event) => {
      invalidate();
    });
    return () => {
      listener.unsubscribe();
    };
  }, [invalidate]);

  useEffect(() => {
    return () => {
      client.cancelQueries({
        queryKey: [ctx],
      });
    };
  }, []);

  return {
    data: data ? data['hydra:member'] : undefined,
    count: data ? data['hydra:totalItems'] : undefined,
    isLoading,
  };
}

export default useTasksProvider;
