import React, { FunctionComponent } from 'react';
import DropdownMenu from '../../../../organisms/DropdownMenu/DropdownMenu';
import { ArrowIcon } from '../../../../../Icons/arrow.icon';
import EditIcon from '../../../../../Icons/edit.icon';
import ShareIcon from '../../../../../Icons/Share.icon';
import InsightsIcon from '../../../../../Icons/Insights.icon';
import { BasicProjectType } from '../../../../../@types/Project/Project';
import { DropdownMenuItemType } from '../../../../organisms/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import { SettleButton } from '../innerDropdownMenuItems/SettleButton';
import { CompleteButton } from '../innerDropdownMenuItems/CompleteButton';
import { ReopenButton } from '../innerDropdownMenuItems/ReopenButton';
import { ArchiveButton } from '../innerDropdownMenuItems/ArchiveButton';
import { RestoreButton } from '../innerDropdownMenuItems/RestoreButton';
import Popover from '../../../../atoms/Popover/Popover';
import Text from '../../../../atoms/Text';
import { Icon } from '../../../../atoms/Icon/Icon';
import { color } from '../../../../../styles/Variables';
import Chip from '../../../../atoms/Chips/Chip';

type Props = {
  status: BasicProjectType['status'];
  archived: BasicProjectType['archived'];
  id: number;
  onEdit: () => void;
};

const ProjectHeaderDropdownMenu: FunctionComponent<Props> = ({ status, archived, id, onEdit }) => {
  const innerMenu: DropdownMenuItemType[] = (() => {
    let innerMenu = [];
    if (status === 'active') {
      innerMenu.push(CompleteButton(id));
      innerMenu.push(SettleButton(id));
    } else if (status === 'completed') {
      innerMenu.push(ReopenButton(id));
      innerMenu.push(SettleButton(id));
    } else if (status === 'settled') {
      innerMenu.push(ReopenButton(id));
    }
    if (!archived && status === 'settled') {
      innerMenu.push(ArchiveButton(id));
    } else if (archived) {
      innerMenu.push(RestoreButton(id));
    }
    return innerMenu;
  })();
  return (
    <DropdownMenu
      menuItems={[
        {
          icon: (
            <Icon color={color.neutral[70]}>
              <EditIcon />
            </Icon>
          ),
          text: 'Edit details',
          onClick: () => {
            onEdit();
          },
        },
        {
          icon: (
            <Icon color={color.neutral[70]}>
              <ShareIcon />
            </Icon>
          ),
          text: (
            <Popover popover={<Text size={'s'}>Copied!</Text>} duration={2000}>
              Copy link
            </Popover>
          ),
          onClick: () => {
            navigator.clipboard.writeText(window.location.href);
          },
        },
        {
          icon: (
            <Icon color={color.neutral[70]}>
              <InsightsIcon />
            </Icon>
          ),
          text: 'Change status',
          onClick: () => {},
          children: [...innerMenu],
        },
      ]}
    >
      <Chip $bgColor={'transparent'} $hoverColor={color.neutral['40']}>
        <Icon color={color.neutral[70]}>
          <ArrowIcon />
        </Icon>
      </Chip>
    </DropdownMenu>
  );
};

export default ProjectHeaderDropdownMenu;
